/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
    require("what-input")
}

exports.onClientEntry = () => {
  if (typeof window !== "undefined" && window.analytics) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("gclid") !== null) {
      window.analytics.identify({gclid: searchParams.get("gclid")})
    } else if (searchParams.get("twclid") !== null) {
      window.analytics.identify({twclid: searchParams.get("twclid")})
    }
  }
}

exports.onRouteUpdate = () => {
  typeof window !== "undefined" && window.analytics && window.analytics.page("", document.title, {
    url: window.location.href,
    search: window.location.search,
    referrer: document.referrer,
    path: window.location.pathname
  });
};

// Forcing render instead of hydrateRoot makes react not output minified errors.
const reactDomClient = require(`react-dom/client`)
exports.replaceHydrateFunction = () => (Component, el) => {
    const root = reactDomClient.createRoot(el)
    root.render(Component)
    return () => root.unmount()
};
